import styled from "styled-components";
import {
  Navbar as ReactNavbar,
  Nav,
  Container as ReactContainer,
  Button,
  Col,
} from "react-bootstrap";
import { COLORS, SVGS } from "../../assets";
import { Link as GastbyLink } from "gatsby";

export const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${COLORS.bege};
`;

export const Content = styled(ReactContainer)`
  position: relative;
`;

export const Column = styled(Col)`
  @media only screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 80%;
  width: auto;
  margin-bottom: 20px;
`;

export const Menu = styled.div`
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
  @media only screen and (max-width: 992px) {
    align-items: center;
  }
`;

export const Link = styled.a`
  color: ${COLORS.primary};
  text-transform: uppercase;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.p`
  color: ${COLORS.primary};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  font-size: 0.875rem;
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const SocialLink = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  :last-child {
    margin-left: 10px;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
`;
