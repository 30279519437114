import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SVGS, COLORS } from "../../assets";
import * as Styles from "./styles.js";

const AboutComponent = () => {
  return (
    <section id="about">
      <Styles.Container>
        <Container>
          <Row className="justify-content-end">
            <Col lg={6} xs={16}>
              <Styles.Title>
                Nós provemos o MELHOR cuidado para o seu PET
              </Styles.Title>
              <Styles.Description>
                A PetVitta conta com clínica, loja, centro cirúrgico e
                internação, centro de estética, hotel e creche para o seu amigo.
              </Styles.Description>
              <Styles.Button href="#services">Nossos Serviços</Styles.Button>
            </Col>
          </Row>
        </Container>
      </Styles.Container>
    </section>
  );
};

export default AboutComponent;
