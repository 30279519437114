import styled from "styled-components";
import {
  Navbar as ReactNavbar,
  Nav,
  Container as ReactContainer,
  Button,
  Row,
} from "react-bootstrap";
import { COLORS, SVGS } from "../../assets";

export const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${COLORS.bege};
`;

export const Content = styled(ReactContainer)`
  position: relative;
`;

export const Description = styled.div`
  color: ${COLORS.primary};
  margin-bottom: 40px;
`;

export const Photos = styled.div`
  color: ${COLORS.primary};
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;
