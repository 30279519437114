import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { SVGS, COLORS } from "../../assets";
import * as Styles from "./styles.js";
import * as Components from "../../styles/index";

const ServicesComponent = () => {
  return (
    <section id="services">
      <Styles.Container>
        <Components.Title>Nossos Serviços</Components.Title>
        <Row>
          <Col lg={4}>
            <Styles.Item>
              <Styles.IconContainer>
                <Styles.Icon
                  src={SVGS.bone}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.IconContainer>
              <Styles.TextContainer>
                <Styles.ItemTitle>LOJA PRESENCIAL & ONLINE</Styles.ItemTitle>
                <Styles.ItemDescription>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  Textinho{" "}
                </Styles.ItemDescription>
              </Styles.TextContainer>
            </Styles.Item>
          </Col>
          <Col lg={4}>
            <Styles.Item>
              <Styles.IconContainer>
                <Styles.Icon
                  src={SVGS.veterinarian}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.IconContainer>
              <Styles.TextContainer>
                <Styles.ItemTitle>CLÍNICA VETERINÁRIA</Styles.ItemTitle>
                <Styles.ItemDescription>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  Textinho{" "}
                </Styles.ItemDescription>
              </Styles.TextContainer>
            </Styles.Item>
          </Col>
          <Col lg={4}>
            <Styles.Item>
              <Styles.IconContainer>
                <Styles.Icon
                  src={SVGS.petShower}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.IconContainer>

              <Styles.TextContainer>
                <Styles.ItemTitle>CENTRO DE ESTÉTICA</Styles.ItemTitle>
                <Styles.ItemDescription>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  Textinho{" "}
                </Styles.ItemDescription>
              </Styles.TextContainer>
            </Styles.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Styles.Item>
              <Styles.IconContainer>
                <Styles.Icon
                  src={SVGS.veterinaryClinic}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.IconContainer>
              <Styles.TextContainer>
                <Styles.ItemTitle>
                  CENTRO CIRÚRGICO & INTERNAÇÃO
                </Styles.ItemTitle>
                <Styles.ItemDescription>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  Textinho{" "}
                </Styles.ItemDescription>
              </Styles.TextContainer>
            </Styles.Item>
          </Col>
          <Col lg={4}>
            <Styles.Item>
              <Styles.IconContainer>
                <Styles.Icon
                  src={SVGS.petHouse}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.IconContainer>
              <Styles.TextContainer>
                <Styles.ItemTitle>HOTEL & CRECHE</Styles.ItemTitle>
                <Styles.ItemDescription>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  Textinho{" "}
                </Styles.ItemDescription>
              </Styles.TextContainer>
            </Styles.Item>
          </Col>
        </Row>
      </Styles.Container>
    </section>
  );
};

export default ServicesComponent;
