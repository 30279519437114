import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { SVGS, COLORS, IMAGES } from "../../assets";
import * as Styles from "./styles.js";
import * as Components from "../../styles/index";

const HotelComponent = () => {
  return (
    <section id="hotel">
      <Styles.Container>
        <Styles.Content>
          <Row className="no-gutters">
            <Col lg={4} sm={12} md={12}>
              <Components.Title>HOTEL & CRECHE</Components.Title>
              <Styles.Description>
                <p>
                  Textinho Textinho Textinho Textinho Textinho Textinho Textinho
                  TextInho Textinho Textinho Textinho TextInho Textinho Textinho
                  Textinho TextInho Textinho Textinho Textinho TextInho Textinho
                  Textinho Textinho Textinho Textinho Textinho Textinho TextInho
                  Textinho Textinho Textinho TextInho Textinho Textinho Textinho
                  TextInho Textinho Textinho Textinho TextInho
                </p>
                <Components.Button>GUIA DO HOTEL & CRECHE</Components.Button>
              </Styles.Description>
            </Col>
            <Col lg={8} sm={12} md={12}>
              <Styles.Photos>
                <Styles.Image
                  src={IMAGES.hotelImages}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
              </Styles.Photos>
            </Col>
          </Row>
        </Styles.Content>
      </Styles.Container>
    </section>
  );
};

export default HotelComponent;
