import styled from "styled-components";
import {
  Navbar as ReactNavbar,
  Nav,
  Container as ReactContainer,
  Row,
} from "react-bootstrap";
import { COLORS, SVGS } from "../../assets";
import { withPrefix } from "gatsby";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${COLORS.lightGreen};
`;

export const BG = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* @media (max-width: 1025px) {
    background-image: url(${SVGS.leafsBGMobile});
  } */
  @media (min-width: 1025px) {
    background-image: url(${(props) => props.image});
    background-position: ${(props) => props.position};
  }
  z-index: 0;
`;

export const BGContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.lightGreen};
`;
export const All = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
`;

export const Logo = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 320px) {
    width: 100%;
    height: 200px;
    background-size: contain;
    background-image: url(${SVGS.verticalLogo});
  }
  @media (min-width: 1025px) {
    max-width: 100%;
    height: 200px;
    background-size: contain;
    background-image: url(${SVGS.horizontalLogo});
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  letter-spacing: 0.05em;
  text-align: center;
`;

export const Subtitle = styled.h3`
  letter-spacing: 0.05em;
  text-align: center;
  font-weight: 400;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;
