import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { SVGS, COLORS, IMAGES } from "../../assets";
import * as Styles from "./styles.js";
import * as Components from "../../styles/index";

const FooterComponent = () => {
  return (
    <section id="footer">
      <Styles.Container>
        <Styles.Content>
          <Row className="no-gutters">
            <Styles.Column lg={4} sm={12} md={12}>
              <Styles.Image
                src={SVGS.horizontalLogo}
                className="d-inline-block align-top"
                alt="PetVitta Aracaju"
              />
            </Styles.Column>
            <Styles.Column lg={3} sm={12} md={12}>
              <Styles.Menu>
                <Styles.Link href="#about">Quem Somos</Styles.Link>
                <Styles.Link href="#services">Nossos Serviços</Styles.Link>
                <Styles.Link href="#about">Blog</Styles.Link>
                <Styles.Link>Loja Online</Styles.Link>
              </Styles.Menu>
            </Styles.Column>
            <Styles.Column lg={3} sm={12} md={12}>
              <Styles.SectionTitle>NOSSAS REDES SOCIAIS</Styles.SectionTitle>
              <Styles.SocialContainer>
                <Styles.SocialLink>
                  <img
                    width="25px"
                    src={SVGS.instagram}
                    className="d-inline-block align-top"
                    alt="PetVitta Aracaju"
                  />
                </Styles.SocialLink>
                <Styles.SocialLink>
                  <img
                    width="25px"
                    src={SVGS.whatsapp}
                    className="d-inline-block align-top"
                    alt="PetVitta Aracaju"
                  />
                </Styles.SocialLink>
              </Styles.SocialContainer>
            </Styles.Column>
            <Styles.Column lg={2} sm={12} md={12}>
              <Styles.Link>
                <Styles.Icon
                  width="25px"
                  src={SVGS.callPhone}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
                (79) - 00000-0000
              </Styles.Link>
              <Styles.Link>
                <Styles.Icon
                  width="25px"
                  src={SVGS.pin}
                  className="d-inline-block align-top"
                  alt="PetVitta Aracaju"
                />
                Nossos Serviços
              </Styles.Link>
            </Styles.Column>
          </Row>
        </Styles.Content>
      </Styles.Container>
    </section>
  );
};

export default FooterComponent;
