import React from "react";
import * as Styles from "./styles";

function IconButton({ icon, size, href, marginRight, ...props }) {
  return (
    <Styles.Container
      size={size}
      href={href}
      marginRight={marginRight}
      target="_blank"
      {...props}
    >
      <Styles.Icon src={icon} size={size} />
    </Styles.Container>
  );
}

export default IconButton;
