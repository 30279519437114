import styled from "styled-components";
import { Button as ButtonReact } from "react-bootstrap";
import { IMAGES, COLORS } from "../../assets";

export const Container = styled.div`
  background-image: url(${IMAGES.dog});
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${COLORS.primary};
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const Description = styled.h5`
  color: ${COLORS.black};
  font-weight: 400;
  letter-spacing: 0.05rem;
  margin-bottom: 2rem;
`;

export const Button = styled.a`
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  border: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.875rem;
  padding: 1rem 1.875rem;
  outline: 0;
  border-radius: 5px;

  :hover {
    color: ${COLORS.white};
    text-decoration: none;
  }
`;
