import styled from "styled-components";
import { COLORS } from "../../assets";

const sizes = {
  small: 30,
  large: 60,
};

export const Container = styled.a`
  margin-right: ${(props) => props.marginRight};
`;

export const Icon = styled.img`
  width: ${(props) => sizes[props.size] || 30}px;
  height: ${(props) => sizes[props.size] || 30}px;
  fill: ${COLORS.black};
`;
