import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SVGS, COLORS, IMAGES } from "../../assets";
import * as Styles from "./styles";
import SmallFooter from "../SmallFooter";
import IconButton from "../IconButton";
import SEO from "../SEO";

function Maintenance() {
  const [position, setPosition] = useState();

  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e) => {
    const position = parallax(e);
    setPosition(position);
  };

  function parallax(e) {
    let _w = window.innerWidth / 2;
    let _h = window.innerHeight / 2;
    let _mouseX = e.clientX;
    let _mouseY = e.clientY;
    let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${
      50 - (_mouseY - _h) * 0.01
    }%`;
    let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${
      50 - (_mouseY - _h) * 0.02
    }%`;
    let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${
      50 - (_mouseY - _h) * 0.06
    }%`;
    let x = `${_depth3}, ${_depth2}, ${_depth1}`;

    return x;
  }

  return (
    <Styles.Wrapper>
      <SEO />
      <Styles.BGContainer>
        <Styles.BG position={position} image={SVGS.leafsBG} />
      </Styles.BGContainer>
      <Styles.All>
        <Styles.Container>
          <Styles.Content>
            <Styles.Logo alt="PetVitta Aracaju" />
            <Styles.Title>Website em construção</Styles.Title>
            <Styles.Subtitle>
              Em breve uma experiência incrível e <br />{" "}
              <u>
                <b>completa</b>
              </u>{" "}
              para o seu pet.
            </Styles.Subtitle>
            <Styles.ButtonsContainer>
              <IconButton
                icon={SVGS.pinBlack}
                size={"large"}
                href="https://goo.gl/maps/smqozfPubxHdF7Ps7"
                marginRight="20px"
                alt="Como chegar PetVitta Aracaju"
              />
              <IconButton
                icon={SVGS.whatsappBlack}
                alt="WhatsApp PetVitta Aracaju"
                size={"large"}
                href="https://api.whatsapp.com/send?phone=5579999610101"
              />
            </Styles.ButtonsContainer>
          </Styles.Content>
          <SmallFooter textAlign={"center"} color={COLORS.black} />
        </Styles.Container>
      </Styles.All>
    </Styles.Wrapper>
  );
}

export default Maintenance;
