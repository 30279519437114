import React, { useEffect, useState } from "react";
import "../styles/index.css";
import Navbar from "../components/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutComponent from "../components/about";
import ServicesComponent from "../components/services";
import HotelComponent from "../components/hotel";
// import InstagramComponent from "../components/instagram";
import FooterComponent from "../components/footer";
import { graphql } from "gatsby";
import CONFIG from "../config";
import Maintenance from "../components/maintenance";
import SEO from "../components/SEO";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 800,
    easing: "easeInOutQuad",
  });
}

function Index({ data }) {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch("/api/date");
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  if (CONFIG.MAINTENANCE_MODE) {
    return <Maintenance />;
  }

  return (
    <main>
      <SEO />
      <Navbar />
      <AboutComponent />
      <ServicesComponent />
      <HotelComponent />
      {/* <InstagramComponent data={data} /> */}
      <FooterComponent />
    </main>
  );
}

// export const pageQuery = graphql`
//   query IndexQuery {
//     allInstaNode(filter: { username: { eq: "petvittaaracaju" } }) {
//       edges {
//         node {
//           id
//           username
//           likes
//           caption
//           comments
//           localFile {
//             childImageSharp {
//               fluid(quality: 70, maxWidth: 600, maxHeight: 600) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export default Index;
